import restaurant from "./images/restaurant.jpg";
import img_card from "./images/noi.jpg";
import imgheaderrestaurant from "./images/mvsm.jpg";
import imgheader from "./images/header.webp";
import imgheaderm from "./images/headermb.webp";
import img2 from "./images/img2.jpg";
import imgheadermiini from "./images/ring.webp";
import imgheadermiinimb from "./images/header.jpg";

{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        title: "Invitatie Online",
        mire: "Sandu",
        mireasa: "Sanda",
        data: "3 August 2024",
        data_confirmare: "15 iulie 2024",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "lizasanda0104@gmail.com", 
       tel: "+37378201075",
       phone: "tel:+37378201075",
       viber: "viber://chat?number=%2B37378201075",
       whatsapp: "https://wa.me/+37378201075",
       messenger: "https://www.messenger.com/t/sanda.liza",
       tel1: "+373xxx",
       phone1: "tel:+373xxx",
       viber1: "viber://chat?number=%2B373xxx",
       whatsapp1: "https://wa.me/+373xxx",
       messenger1: "https://www.messenger.com/t/xx",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Hotel & Banquet Hall SV",
            data: "3 august 2024, sâmbătă, ora 16:00",
            adress: "str Chisinaului 3, Todireşti, r-l. Anenii Noi",
            harta: "https://maps.app.goo.gl/hhixk9txWkf9FBy29",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10901.760678142642!2d29.0845115!3d46.9137274!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c975f9b92aff4d%3A0xb4b5829769509dbb!2sHotel%20%26%20Banquet%20Hall%20SV!5e0!3m2!1sro!2s!4v1707328165848!5m2!1sro!2s" 
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care ne vom uni destinele, vrem să fim inconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
            ps: "",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
             
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgheaderrestaurant,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
           
            img: imgheadermiini,
          
            title: "",
            message: "",
            message1: "NE VOR FI ALĂTURI ȘI NE VOR GHIDA PAȘII PĂRINȚII:",
            parintii_nume1: "Petru şi Natalia Botezatu",
            parintii_nume2: "Anatol şi Marina Liza",
            parintii_nume3: "",
            parintii_nume4: "",
            nasii: "",
            nasii_nume: "",
        }
    ],

}

export default data;